.video-overlay-close {
  position: absolute;
  top: pixel-to-vw(20px);
  right: pixel-to-vw(20px);
  width: pixel-to-vw(45px);
  height: pixel-to-vw(45px);
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 500ms ease;
  color: map_get($colours,neutral-dark);
  font-size: pixel-to-vw(30px);
  font-family: inherit;
  padding: 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: pixel-to-vw(3px) solid map_get($colours,neutral-dark);
    border-radius: 50%;
    transition: border-color 500ms ease, transform 500ms cubic-bezier(0.77, 0, 0.175, 1);
  }
  &:after {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    height: pixel-to-vw(45px);
    line-height: pixel-to-vw(45px);
    content: '\00d7';
  }
  &:hover {
    color: map_get($colours,highlight);
    &:before{
      border-color: map_get($colours,highlight);
      transform: scale(1.05);
    }
  }
  &:focus {
    outline: none;
  }
}