*,*:before,*:after{
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html,#root{
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family: $font-family;
  font-size: $font-size;
  color: $font-colour;
  line-height: $line-height;
  overflow: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.app-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 1080/1920 * 100vw;
  background-color: #fff;
}

.page-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: $vertical-safe-zone $horizontal-safe-zone;
  .version{
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #bfbfbf;
    padding: 5px 10px;
    font-size: .8em;
  }
}