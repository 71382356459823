.map-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
  transition: transform 1500ms cubic-bezier(.3,1,.3,1);
  .map-page-content {
    position: relative;
    flex: 0 0 auto;
  }

  &.open-content {
    transform: translateX(-34%);
  }
}

.map-main-background {
  position: absolute;
  top: 0;
  left: 0;
  width: pixel-to-vw(230px);
  height: pixel-to-vw(230px);
  background-color: #fff;
  border-radius: 50%;
  transform: scale(1.05);
  z-index: 2;
}

.map-main {
  position: relative;
  width: pixel-to-vw(230px);
  height: pixel-to-vw(230px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: pixel-to-vw(30px);
  z-index: 3;

  h1{
    position: relative;
  }

  .map-title {
    max-width: 100%;
    flex: 0 0 1;
    text-align: center;
    transition: opacity 250ms ease-in-out;
    &.transitioning {
      opacity: 0;
    }
    .map-decoration {
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      text-align: center;
      color: map_get($colours,highlight);
      transform: translateY(-125%);
      font-size: pixel-to-vw(25px);
    }
  }

  &:before {
    content: '';
    @include absolute-fill();
    border: $circle-border-width solid map_get($colours,primary);
    border-radius: 50%;
    transition: border-color 500ms ease, transform 500ms cubic-bezier(0.77, 0, 0.175, 1);
  }
  &.hoverable {
    cursor: pointer;
    &:hover {
      &:before {
        transform: scale(1.05);
        border-color: map_get($colours, highlight);
      }
    }
    &:not(:hover) {
      /**&:before {
        transition: border-color 500ms ease, transform 500ms cubic-bezier(0.77, 0, 0.175, 1);
        animation: circle-grow 3000ms ease-in-out infinite;
      }*/
      &:after {
        content: '';
        @include absolute-fill();
        opacity: 0;
        border: $circle-border-width solid map_get($colours, primary);
        border-radius: 50%;
        animation: circle-pulse 3000ms ease-out infinite;
      }
    }
  }
}


.map-children{
  @include absolute-fill();
  display: flex;
  justify-content: center;
  height: 50%;
  z-index: 1;
  white-space: nowrap;

  &.entering {
    .map-child-divider{
      animation: line-grow 650ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    }
    .map-child-container{
      animation: content-appear 250ms ease-in forwards;
    }
  }

  &.exiting {
    .map-child-divider{
      height: pixel-to-vw(50px);
      max-height: pixel-to-vw(50px);
      animation: line-shrink 650ms linear forwards;
    }
    .map-child-container{
      opacity: 1;
      animation: content-appear 250ms ease-in reverse forwards;
    }
  }
}

.map-child-container {
  position: absolute;
  left: calc(50% - #{pixel-to-vw(43.5px)});
  bottom: 0;
  padding-bottom: 50%;
  opacity: 0;
  transform-origin: center bottom;
  .map-child-divider {
    position: relative;
    width: $circle-border-width;
    height: 0;
    margin: pixel-to-vw(17px) auto;
    transform-origin: center bottom;
    background-color: map_get($colours,highlight);
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: map_get($colours,highlight);
      transform: translateX(-50%);
      @media screen and (max-width: 1160px){
        width: 8px;
        height: 8px;
      }
      @media screen and (max-width: 860px){
        width: 6px;
        height: 6px;
      }
    }
    &:before{
      top: 0;
    }
    &:after{
      bottom: 0;
    }
  }
  .map-child-content-container {
    cursor: pointer;
    -webkit-transform-origin: 50%  51%;
    transform-origin: 50%  51%;
    .map-child-icon {
      position: relative;
      width: pixel-to-vw(87px);
      height: pixel-to-vw(87px);
      padding: pixel-to-vw(15px);
      &:before {
        content: '';
        @include absolute-fill();
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border: $circle-border-width solid map_get($colours,primary);
        border-radius: 50%;
        transition: border-color 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55),
                    height 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55),
                    width 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }
    }
    .map-child-name {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
      position: absolute;
      top: 0;
      left: 0;
      min-width: pixel-to-vw(180px);
      height: pixel-to-vw(87px);
      font-weight: bold;
      line-height: 1.25;
      color: map_get($colours,neutral-light);
      transition: color 500ms ease;
      opacity: 0;
      animation: content-appear 1000ms ease forwards;
      &:not(.left):not(.top){
        padding-left: pixel-to-vw(110px);
      }
      &.left {
        padding-right: pixel-to-vw(23px);
        text-align: right;
        transform: translateX(-100%);
      }
      &.top {
        display: block;
        min-width: pixel-to-vw(100px);
        top: -70%;
        left: 50%;
        text-align: center;
        transform: translateX(-50%);
      }
    }

    &:hover {
      .map-child-icon:before{
        width: 105%;
        height: 105%;
        border-color: map_get($colours,highlight);
        transition: border-color 500ms ease, width 500ms ease, height 500ms ease;
      }
      .map-child-name{
        color: map_get($colours,primary);
      }
    }
  }
}

.map-child-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: pixel-to-vw(88px);
  height: pixel-to-vw(286px);
  left: calc(50% - #{pixel-to-vw(43.5px)});
  bottom: 0;
  padding-bottom: 50%;
  opacity: 0;
  transform-origin: center bottom;
  .map-child-position {
    position: absolute;
    top: pixel-to-vw(43.5px);
    left: 50%;
    width: 0;
    height: 0;
  }
  .map-child-divider {
    position: relative;
    flex: 0 0 auto;
    width: $circle-border-width;
    height: 0;
    margin: pixel-to-vw(17px) auto;
    transform-origin: center bottom;
    background-color: map_get($colours,highlight);
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: map_get($colours,highlight);
      transform: translateX(-50%);
      @media screen and (max-width: 1160px){
        width: 8px;
        height: 8px;
      }
      @media screen and (max-width: 860px){
        width: 6px;
        height: 6px;
      }
    }
    &:before{
      top: 0;
    }
    &:after{
      bottom: 0;
    }
  }
}

.map-children-tree-wrap {
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .map-children-tree {
    display: flex;
    align-items: stretch;
    .map-children-scaffold{
      flex: 0 0 auto;
      position: relative;
      width: pixel-to-vw(66px);
      margin: 0 pixel-to-vw(22px) 0 pixel-to-vw(22px);
      z-index: 1;
    }
    .map-children-tree-list{
      position: relative;
      z-index: 2;
      &.exiting {
        opacity: 0;
        transition: opacity 125ms ease;
      }
    }
    .map-tree-child {
      display: flex;
      align-items: center;
      cursor: pointer;
      .map-tree-child-icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        width: pixel-to-vw(82px);
        height: pixel-to-vw(82px);
        margin: 0 pixel-to-vw(20px) 0 0;
        opacity: 0;
        padding: pixel-to-vw(15px);
        transform: scale(0);
        transition: opacity 500ms ease;
        animation: icon-entrance 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
        &:before {
          content: '';
          @include absolute-fill();
          border: $circle-border-width solid map_get($colours,primary);
          border-radius: 50%;
          transition: border-color 500ms ease, transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }
        img{
          transition: opacity 500ms ease;
          display: block;
          max-width: 100%;
          max-height: 100%;
        }
      }
      .map-tree-child-name{
        flex: 0 0 auto;
        font-weight: bold;
        color: map_get($colours,neutral-light);
        transition: color 500ms ease;
        opacity: 0;
        max-width: 140px;
        white-space: nowrap;
        line-height: 1.3;
        &.visible {
          animation: content-appear 500ms ease-in 500ms forwards;
        }

      }
      &:not(.active) {
        &:hover {
          .map-tree-child-icon {
            &:before {
              opacity: 1;
              transform: scale(1.05);
              border-color: map_get($colours, highlight);
              transition: border-color 500ms ease, transform 500ms cubic-bezier(0.77, 0, 0.175, 1);
            }
            img{
              opacity: 1;
            }
          }
          .map-tree-child-name {
            color: map_get($colours, primary);
          }
        }
      }
      &.active {
        cursor: default;
        .map-tree-child-icon:before{
          border-color: map_get($colours, primary);
        }
        .map-tree-child-name {
          color: map_get($colours, primary);
        }
      }
      &.faded {
        .map-tree-child-icon:before{
          border-color: map_get($colours,neutral-light);
        }
        .map-tree-child-icon img{
          opacity: 0.18;
        }
      }
      +.map-tree-child{
        margin-top: pixel-to-vw(15px);
      }
    }

  }
}

.map-children-scaffold-line-container {
  height: 100%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-start;
  width: pixel-to-vw(50px);
  .map-children-scaffold-line {
    position: relative;
    flex: 0 0 auto;
    height: 3px;
    transform-origin: left center;
    background-color: map_get($colours, highlight);
    &:before,
    &:after {
      flex: 0 0 auto;
      content: '';
      position: absolute;
      top: 50%;
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: map_get($colours, highlight);
      transform: translateY(-50%);
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}

.map-children-scaffold-canvas {
  @include absolute-fill();
  height: calc(100% - #{pixel-to-vw(82px)} + 4px);
  top: #{pixel-to-vw(41px)};
  margin-top: -2px;

  .map-children-scaffold-main-wrap {
    @include absolute-fill();
    overflow: hidden;
    .map-children-scaffold-main {
      position: relative;
      left: 50%;
      width: 200%;
      height: 100%;
      border: $circle-border-width solid #fff;
      border-radius: pixel-to-vw(10px);
      animation: border-colour-delay 1ms linear 250ms forwards;
      &:before {
        position: absolute;
        top: calc(50% - #{$circle-border-width/2});
        right: 100%;
        content: '';
        display: block;
        width: 100%;
        height: $circle-border-width;
        background-color: map_get($colours,highlight);
      }
    }
  }

  .map-children-scaffold-indicator{
    position: absolute;
    top: 50%;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: map_get($colours,highlight);
    z-index: 4;
    transform: translateY(-50%);
  }
  .map-children-scaffold-cover-middle{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: #fff;
  }
  .map-children-scaffold-cover{
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 2;
    background-color: #fff;
    opacity: 1;
    &:before{
      content: '';
      position: absolute;
      left: calc(50% + 1px);
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: map_get($colours,highlight);
      transform: translateX(-50%);
    }
    &.top {
      top: 2px;
      transform-origin: bottom center;
      &:before {
        bottom: -5px
      }
    }
    &.bottom {
      bottom: 2px;
      transform-origin: top center;
      &:before{
        top: -5px
      }
    }
  }
}

.map-children-tree-wrap {
  &:not(.exiting) {
    .map-children-scaffold-main {
      border: $circle-border-width solid #fff;
      animation: border-colour-delay 1ms linear 250ms forwards;
    }
    .map-children-scaffold-cover-middle {
      animation: line-reveal-first 250ms linear forwards;
    }
    .map-children-scaffold-cover {
      &.top.animate {
        animation: line-reveal-up 500ms linear forwards;
      }
      &.bottom.animate {
        animation: line-reveal-down 500ms linear forwards;
      }
    }
    .map-children-scaffold-line {
      width: 0;
      opacity: 0;
      animation: line-grow-horiz 650ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 300ms forwards;
    }
  }
  &.exiting {
    .map-children-scaffold-main {
      border: $circle-border-width solid map_get($colours,highlight);
      animation: border-colour-delay 1ms linear 240ms forwards reverse;
    }
    .map-children-scaffold-cover-middle {
      transform: translateX(150%);
      animation: line-reveal-first 250ms linear forwards reverse 240ms;
    }
    .map-children-scaffold-cover {
      &.top {
        transform: translateY(-100.5%) translateX(40%) rotate(90deg) ;
        animation: line-reveal-up 250ms linear forwards reverse;
      }
      &.bottom {
        transform: translateY(100.5%) translateX(40%) rotate(-90deg);
        animation: line-reveal-down 250ms linear forwards reverse;
      }
    }
    .map-children-scaffold-line {
      width: pixel-to-vw(50px);
      animation: line-grow-horiz 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards reverse;
    }
  }
}


@keyframes line-grow{
  from {
    height: 0;
  }
  to {
    height: pixel-to-vw(50px);
  }
}
@keyframes line-grow-horiz {
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: pixel-to-vw(50px);
    opacity: 1;
  }
}
@keyframes line-shrink{
  from {
    height: pixel-to-vw(50px);
  }
  to {
    height: 0;
  }
}
@keyframes content-appear{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes icon-entrance{
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes line-reveal-first{
  from{
    transform: none
  }
  to {
    transform: translateX(150%);
  }
}
@keyframes line-reveal-up {
  0% {
    transform: translateY(-2%);
  }
  80% {
    transform: translateY(-99%);
  }
  100%{
    transform: translateY(-100.5%) translateX(40%) rotate(90deg) ;
  }
}
@keyframes line-reveal-down {
  0% {
    transform: translateY(2%);
  }
  80% {
    transform: translateY(99%);
  }
  100%{
    transform: translateY(100.5%) translateX(40%) rotate(-90deg);
  }
}
@keyframes border-colour-delay {
  from {
    border: $circle-border-width solid #fff;
  }
  to {
    border: $circle-border-width solid map_get($colours,highlight);
  }
}
@keyframes circle-pulse {
  0 {
    transform: none;
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  66.666% {
    transform: none;
    opacity: 1
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes circle-grow {
  0 {
    transform: none;
  }
  50% {
    transform: none;
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: none;
  }
}