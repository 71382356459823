$content-animation-delays: 500ms;

.content-page-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  padding: $vertical-safe-zone $horizontal-safe-zone $vertical-safe-zone 0;
  z-index: 30;
  &.exiting {
    animation: fade-in 250ms ease forwards reverse;
  }
  &:not(.exiting) {
    .content-page {
      .content-page-title,
      .content-page-media-container,
      .content-page-cols .content-page-col {
        opacity: 0;
        animation: fade-in 650ms ease forwards;
      }
      &.text {
        .content-page-cols .content-page-col{
          &:nth-child(1){
            animation-delay: $content-animation-delays;
          }
          &:nth-child(2){
            animation-delay: $content-animation-delays*2;
          }
        }
      }
      &.image {
        .content-page-title {
          animation-delay: $content-animation-delays;
        }
        .content-page-cols .content-page-col{
          &:nth-child(1){
            animation-delay: $content-animation-delays*2;
          }
          &:nth-child(2){
            animation-delay: $content-animation-delays*3;
          }
        }
      }
      &.video {
        .content-page-cols .content-page-col{
          &:nth-child(1){
            animation-delay: $content-animation-delays;
          }
          &:nth-child(2){
            animation-delay: $content-animation-delays*2;
          }
        }
        .content-page-media-container {
          animation-delay: $content-animation-delays*3;
        }
      }
    }
  }
}

.content-page {
  width: 100%;
  .content-page-title {
    position: relative;
    padding-top: pixel-to-vw(20px);
    margin-bottom: pixel-to-vw(30px);
    .content-page-title-decoration {
      position: absolute;
      top: pixel-to-vw(-22px);
      display: block;
      width: 100%;
      text-align: left;
      color: map_get($colours,highlight);
      font-size: pixel-to-vw(28px);
    }
    h2{
      font-size: pixel-to-vw(28px);
      font-weight: bold;
      line-height: 1;
    }
  }
  .content-page-media-container {
    position: relative;
    &:not(:first-child){
      margin-top: pixel-to-vw(40px);
    }
    &:not(:last-child){
      margin-bottom: pixel-to-vw(40px);
    }
    .content-page-media-image{
      position: relative;
      width: 100%;
      &:before{
        content: '';
        display: block;
        padding-top: 486/864*100%;
      }
      img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
    .image-placeholder {
      display: block;
      width: 100%;
      height: pixel-to-vw(485px);
      background-color: #f4f4f4;
    }
    .content-page-media-controls{
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    .content-page-media-video{
      position: relative;
      display: block;
      width: 100%;
      cursor: pointer;
      &:before{
        content: '';
        display: block;
        padding-top: 1080/1920*100%;
      }
      .content-page-media-video-wrap{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transition: 850ms cubic-bezier(.3,1,.3,1);
      }
      .content-page-media-video-backdrop {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transition: opacity 400ms;
        background-color: white;
        opacity: 0;
      }
      .content-page-media-video-centre{
        @include absolute-fill();
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .content-page-media-video-ratio{
        flex: none;
        position: relative;
        width: 100%;
        max-width: 1920px;
        max-height: 1080px;
        border: 1px solid #c9c9c9;
        overflow: hidden;
        &:before{
          content: '';
          display: block;
          padding-top: 1080/1920*100%;
        }
        video{
          @include absolute-fill();
        }
      }

      &.full-screen {
        .content-page-media-video-wrap{
          width: 100vw;
          height: 100vh;
        }
        .content-page-media-video-backdrop {
          opacity: 1;
          width: 100vw;
          height: 100vh;
        }
      }
    }
  }
  .content-page-cols {
    display: flex;
    font-weight: 300;
    .content-page-col{
      flex: 1 1 pixel-to-vw(380px);
      +.content-page-col{
        margin-left: pixel-to-vw(40px);
      }
    }
    ul{
      list-style: disc;
      padding-left: pixel-to-vw(20px);
    }
  }
  &.text{
    .content-page-cols {
      max-height: pixel-to-vw(555px);
    }
  }
  &.image{
    .content-page-cols {
      max-height: pixel-to-vw(265px);
    }
  }
}

.play-button {
  position: relative;
  width: pixel-to-vw(83px);
  height: pixel-to-vw(83px);
  border-radius: 50%;
  border: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  transition: color 500ms ease;
  cursor: pointer;
  span {
    position: relative;
    z-index: 3;
  }
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: $circle-border-width solid map_get($colours,primary);
    border-radius: 50%;
    transition: border-color 500ms ease, transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    z-index: 2;
  }
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 50%;
    z-index: 1;
  }
  &:hover {
    color: map_get($colours,highlight);
    &:before{
      transform: scale(1.05);
    }
    &:after{
      border-color: map_get($colours,highlight);
      transform: scale(1.05);
      transition: border-color 500ms ease, transform 500ms cubic-bezier(0.77, 0, 0.175, 1);
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}