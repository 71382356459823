.loader-container {
  @include absolute-fill();
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.loader {
  width: pixel-to-vw(80px);
  border: pixel-to-vw(8px) solid map_get($colours,highlight);
  border-top-color: #fff;
  border-radius: 50%;
  animation: loader-spin 850ms ease-in-out infinite;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

@keyframes loader-spin{
  to {
    transform: rotate(360deg);
  }
}