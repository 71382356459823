.breadcrumb-container {
  position: absolute;
  top: $vertical-safe-zone;
  left: $horizontal-safe-zone;
  font-weight: bold;
  z-index: 10;
  li {
    display: inline-block;
    vertical-align: middle;
    color: map_get($colours, neutral-light);
    transition: color 500ms ease;
    &:not(.exiting) {
      opacity: 0;
      animation: breadcrumb-fade-in 500ms ease-in forwards;
    }
    &.exiting {
      animation: breadcrumb-fade-in 500ms ease-out forwards reverse;
    }
    &:last-child {
      color: map_get($colours, primary);
    }
    &:not(:last-child) {
      cursor: pointer;
      &:hover {
        color: map_get($colours, highlight);
      }
    }
    + li {
      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: pixel-to-vw(5px);
        height: pixel-to-vw(5px);
        background-color: map_get($colours,neutral-light);
        border-radius: 50%;
        margin: 0 pixel-to-vw(30px);
        transition: color 500ms ease;
      }
      &:last-child:before{
        background-color: map_get($colours,primary);
      }
      br{
        display: none;
      }
    }
  }
}

@keyframes breadcrumb-fade-in{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}