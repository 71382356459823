h1 {
  font-size: pixel-to-vw(25px);
  font-weight: bold;
  line-height: 1.2;
}
p{
  &:not(:last-child){
    margin-bottom: pixel-to-vw(24px);
  }
}
strong{
  font-weight: bold;
}