.back-button-container{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: calc(#{$horizontal-safe-zone} - 5px);
  z-index: 2;
  button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: pixel-to-vw(83px);
    height: pixel-to-vw(83px);
    margin: 5px;
    border: none;
    border-radius: 0;
    background-color: transparent;
    color: map_get($colours,neutral-dark);
    font-weight: bold;
    font-size: inherit;
    font-family: inherit;
    text-align: center;
    cursor: pointer;
    transition: color 500ms ease;
    -webkit-appearance: none;
    span{
      width: 100%;
      flex: 1 1 auto;
      display: block;
      text-align: center;
      transition: opacity 500ms ease;
      &.transitioning {
        opacity: 0;
      }
    }
    &:before {
      content: '';
      @include absolute-fill();
      border: $circle-border-width solid map_get($colours,neutral-dark);
      border-radius: 50%;
      transition: border-color 500ms ease, transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
    @media( hover: hover ) {
      &:hover {
        color: map_get($colours, highlight);
        &:before {
          border-color: map_get($colours, highlight);
          transform: scale(1.05);
          transition: border-color 500ms ease, transform 500ms cubic-bezier(0.77, 0, 0.175, 1);
        }
      }
    }
    &:not(.exiting){
      opacity: 0;
      transform: scale(0);
      animation: button-enter 850ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards 2500ms;
    }
    &.exiting{
      animation: button-enter 500ms ease-out reverse forwards;
    }
    &:focus{
      outline: none;
    }
  }
}

@keyframes button-enter {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}