// Colours
$colours: (
  primary: #000,
  highlight: #ff000f,
  neutral-light: #d2d2d2,
  neutral-dark: #898989
);

// Fonts
$font-family: 'ABBVoice';
$font-size: pixel-to-vw(16px);
$font-colour: map_get($colours,primary);
$line-height: 1.5;

// Safe Zone
$vertical-safe-zone: pixel-to-vw(40px);
$horizontal-safe-zone: pixel-to-vw(80px);

// Border Widths
$circle-border-width: 3px;
$decoration-line-width: 2px;